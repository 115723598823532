import { Body, H5 } from "@casavo/habitat"
import Link from "next/link"
import useTranslation from "next-translate/useTranslation"
import React, { useState } from "react"

import theme from "@app/shared/design-lib/style-theme/theme.css"
import { PageKind } from "@app/shared/types/pages"
import { handleUserEventClick } from "@app/shared/utils/tracking/userEvent"
import { BlogCategoryType } from "@shared/types/website-pages"
import * as casavoPages from "@shared/utils/pages"

import {
  button,
  container,
  dropdown,
  inner,
  itemStyle,
  li,
  link,
  list,
  paddingRight,
  select,
  selectBox,
} from "./styles/categoryMenu.css"

export const CategoryMenu: React.FC<{ selected?: string; showAllArticles?: boolean }> = ({
  selected,
  showAllArticles = false,
}) => {
  const { lang, t } = useTranslation()
  const categories = t<BlogCategoryType>("blog:categories", {}, { returnObjects: true })
  const linkHome = t("blog:categoryMenu.linkHome")
  const allArticles = t("blog:categoryMenu.allArticles")
  const categoryPlaceholder = t("blog:categoryMenu.category")
  const blogUrl = `/${lang}/${casavoPages.create(lang as Locale)(PageKind.blog)}`
  const [hamburgerOpen, setHamburgerOpen] = useState(false)

  return (
    <div className={container}>
      <section className={inner}>
        {showAllArticles ? (
          <Link aria-label={linkHome} className={link} href={blogUrl}>
            <span className={paddingRight}>
              <svg fill="none" height="16" viewBox="0 0 10 16" width="10" xmlns="http://www.w3.org/2000/svg">
                <path
                  clipRule="evenodd"
                  d="M8.58579 0.292847L0.878679 7.99995L8.58579 15.7071L10 14.2928L3.70789 7.99995L10 1.70706L8.58579 0.292847Z"
                  fill={theme.colors.greyscale600}
                  fillRule="evenodd"
                />
              </svg>
            </span>
            <H5 noMargin>{linkHome}</H5>
          </Link>
        ) : null}
        <nav>
          <ul className={list}>
            {showAllArticles ? (
              <li className={li}>
                <Link legacyBehavior aria-label={allArticles} href={`${blogUrl}/all-articles`}>
                  <button
                    className={button({ isActive: selected === "all-articles" })}
                    onClick={() => handleUserEventClick("AllArticles")}
                  >
                    <Body noMargin>{allArticles}</Body>
                  </button>
                </Link>
              </li>
            ) : null}
            {Object.keys(categories)
              .filter((item) => item !== "customer-stories")
              .map((cat) => {
                const href = `${blogUrl}/category/${cat}`
                return (
                  <li key={cat}>
                    <Link legacyBehavior aria-label={categories[cat as keyof BlogCategoryType]} href={href}>
                      <button
                        className={button({ category: true, isActive: selected === cat })}
                        onClick={() => handleUserEventClick(cat.charAt(0).toUpperCase() + cat.slice(1))}
                      >
                        <Body noMargin>{categories[cat as keyof BlogCategoryType]}</Body>
                      </button>
                    </Link>
                  </li>
                )
              })}
          </ul>
          <div className={select}>
            <div className={selectBox} onClick={() => setHamburgerOpen(!hamburgerOpen)}>
              <Body noMargin>{categoryPlaceholder}</Body>
              {hamburgerOpen ? (
                <svg aria-hidden="true" viewBox="0 0 32 32" width="30">
                  <path
                    d="M15.997 13.374l-7.081 7.081L7 18.54l8.997-8.998 9.003 9-1.916 1.916z"
                    fill={theme.colors.greyscale300}
                    fillRule="evenodd"
                  />
                </svg>
              ) : (
                <svg aria-hidden="true" viewBox="0 0 32 32" width="30">
                  <path
                    d="M16.003 18.626l7.081-7.081L25 13.46l-8.997 8.998-9.003-9 1.917-1.916z"
                    fill={theme.colors.greyscale300}
                    fillRule="evenodd"
                  />
                </svg>
              )}
            </div>
            <div className={dropdown({ isOpen: hamburgerOpen })}>
              <ul>
                {allArticles ? (
                  <li>
                    <Link
                      aria-label={allArticles}
                      className={itemStyle({ all: true, isActive: selected === "all-articles" })}
                      href={`${blogUrl}/all-articles`}
                    >
                      <Body noMargin>{allArticles}</Body>
                    </Link>
                  </li>
                ) : null}
                {Object.keys(categories)
                  .filter((item) => item !== "customer-stories")
                  .map((cat) => {
                    const href = `${blogUrl}/category/${cat}`
                    return (
                      <li key={cat} className={itemStyle({ all: false, isActive: selected === cat })}>
                        <Link aria-label={categories[cat as keyof BlogCategoryType]} href={href}>
                          <Body noMargin>{categories[cat as keyof BlogCategoryType]}</Body>
                        </Link>
                      </li>
                    )
                  })}
              </ul>
            </div>
          </div>
        </nav>
      </section>
    </div>
  )
}
